import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from 'react-ga4';

const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID; // Use the environment variable
if(TRACKING_ID){
ReactGA.initialize(TRACKING_ID);
}

// console.log = () => {};

window.alert = function () {};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <App />
  /* </React.StrictMode> */
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
