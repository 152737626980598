import React, { useState } from "react";
import Logo from "../../assets/images/logo.svg";
import {
  contactDetails,
  authlable,
} from "../../utils/hardText/landingpageText";
import RegisterModel from "../userFlow/common/RegisterModal";
import LoginModel from "../userFlow/common/LoginModel";
import ForgetPasswordModel from "../userFlow/common/ForgetPasswordModel";
import RegisterMailPopup from "../userFlow/common/RegisterMailPopup";
import SetNewPasswordModel from "../userFlow/common/SetNewPasswordModel";
import PasswordUpdateModel from "../userFlow/common/PasswordUpdateModel";
import useTopDetailStore from "../../store/TopDetailStore";
import { useLandingStore } from "../../zust/useLandingStore";
import { Link } from "react-router-dom";
import azad from '../../assets/images/azadi_ka_amrit_mahotsav_logo.png';
import z20logo from '../../assets/images/G20_India_2023_logo.png';

interface AuthButtonProps {
  buttontext: string;
  onClick?: () => void;
}

const TopDetail = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const [showLoginModel, setShowLoginModel] = useState(false);
  // const [showForgetModel, setShowForgetModel] = useState(false);
  // const [showRegisterMailModel, setShowRegisterMailModel] = useState(false);
  // const [showsNewPasswordModel, setShowsNewPasswordModel] = useState(true);
  // const [showsPasswordUpdateModel, setShowsPasswordUpdateModel] =
  //   useState(false);

  const { homePageData } = useLandingStore((state) => state);

  const {
    isOpen,
    showLoginModel,
    showForgetModel,
    showRegisterMailModel,
    showsNewPasswordModel,
    showsPasswordUpdateModel,
    setIsOpen,
    setShowLoginModel,
    setShowForgetModel,
    setShowRegisterMailModel,
    setShowsNewPasswordModel,
    setShowsPasswordUpdateModel,
  } = useTopDetailStore();

  const openModal = () => {
    setIsOpen(true);
  };
  const openLoginModel = () => {
    setShowLoginModel(true);
  };

  const showRegisterModel = () => {
    setShowLoginModel(false);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const CloseLoginModal = () => {
    setShowLoginModel(false);
  };

  const ShowForgetModel = () => {
    setShowLoginModel(false);
    setShowForgetModel(true);
  };
  const CloseForgetModel = () => {
    setShowForgetModel(false);
  };
  const CloseforgetModelShowLoginModel = () => {
    setShowForgetModel(false);
    setShowLoginModel(true);
  };

  const CloseForgetPasswordShowRegisterMail = () => {
    // setShowRegisterMailModel(false);
    setShowRegisterMailModel(true);
  };

  const closeRegisterMailPopup = () => {
    setShowForgetModel(false);
    setShowRegisterMailModel(false);
  };
  const closeRegisterMailPopupShowLoginPopup = () => {
    setShowForgetModel(false);
    setShowRegisterMailModel(false);
    setShowLoginModel(true);
  };

  const CloseSetNewPasswordModel = () => {
    setShowsNewPasswordModel(false);
  };

  const CloseNewPasswordandshowUpdatePassword = () => {
    setShowsNewPasswordModel(false);
    setShowsPasswordUpdateModel(true);
  };
  const CloseNewPasswordAndshowLoginModel = () => {
    setShowsNewPasswordModel(false);
    setShowLoginModel(true);
  };

  const CloseUpdatePasswordModel = () => {
    setShowsPasswordUpdateModel(false);
  };

  const CloseUpdatePasswordandShowLogin = () => {
    setShowsPasswordUpdateModel(false);
    setShowLoginModel(true);
  };

  const downloadReport = () => {};

  return (
    <div className="relative flex items-center justify-around flex-row my-[19px] mx-[16px] md:mx-0 flex-wrap gap-2" >
      <div className="flex flex-wrap items-center justify-center md:justify-start gap-2">
      <div className="m-2 md:m-0">
        
        <img src={homePageData?.homePageData?.logo[0]?.img} alt="logo" className="w-[88px] h-[88px]" />
        
      </div>
      
      <div className="text-center sm:text-left ml-4">
          <h1 className="text-[#047A45] text-[20px] font-bold text-gilroy-regular leading-[24px]">CERSAI</h1>
          <p className="text-gilroy-regular leading-[17px] text-[#474747] text-[14px]">Central Registry of Securitisation Asset</p>
          <p className="text-gilroy-regular leading-[17px] text-[#474747] text-[14px]">Reconstruction and Security Interest of India</p>
          <p className="text-gilroy-regular font-bold leading-[17px] text-[#474747] text-[14px]">BUDS Registry </p>
        </div>
        <div className="ml-4 ">
        
        <img src={azad} alt="logo" className="w-[150px]" />
        
      </div>
        <div className="ml-4">
        
        <img src={z20logo} alt="logo" className="w-[150px]" />
        
      </div>
      </div>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-gray-800 opacity-30 z-10"
          onClick={closeModal}
        ></div>
      )}
      <div className="flex flex-wrap justify-around items-center  gap-2">
      <div className="flex items-start justify-start flex-col">
        {homePageData?.homePageData?.contactDetails?.slice(1)?.map((data:any, idx:any) => {
          return (
            <div className="flex items-center justify-center mb-2" key={idx}>
              <div>
                <img src={data?.img} alt="icon" className="w-[24px] h-[24px]" />
              </div>
              {idx === 1 ? (
                <>
                  <div
                    className="ml-4 text-[#797979] text-gilroy-regular cursor-pointer underline"
                    onClick={downloadReport}
                  >
                    <Link target={"_blank"} to={data.link}> {data?.text}</Link>
                   
                  </div>
                </>
              ) : (
                
                <>
                  <div className="ml-4 text-[#797979] text-gilroy-regular">
                    {data?.text}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex items-center md:ml-24">
        <AuthButton buttontext={homePageData?.homePageData?.authlable[1].text} onClick={openModal} />
        <AuthButton buttontext={homePageData?.homePageData?.authlable[0].text} onClick={openLoginModel} />
      </div>
      </div>
      {/* Conditionally render ModelDiv based on isOpen state */}
      {isOpen && (
        <div className="fixed flex-row justify-center items-center mt-[35%] md:mt-[50%] lg:mt-[30%] md:ml-[15%] z-20">
          <RegisterModel closeModal={closeModal} />
        </div>
      )}
      {showLoginModel && (
        <LoginModel
          closeModal={CloseLoginModal}
          showRegisterModel={showRegisterModel}
          ShowForgetModel={ShowForgetModel}
        />
      )}

      {showForgetModel && (
        <ForgetPasswordModel
          closeForgetModel={CloseForgetModel}
          closeforgetModelShowLoginModel={CloseforgetModelShowLoginModel}
          closeForgetPasswordandShowRegisterMail={
            CloseForgetPasswordShowRegisterMail
          }
        />
      )}

      {showRegisterMailModel && (
        <RegisterMailPopup
          closeRegisterMailPopup={closeRegisterMailPopup}
          showLoginPopup={closeRegisterMailPopupShowLoginPopup}
        />
      )}
      {/* {showsNewPasswordModel && (
        <SetNewPasswordModel
          closeSetNewPasswordModel={CloseSetNewPasswordModel}
          closeNewPasswordandshowUpdatePassword={
            CloseNewPasswordandshowUpdatePassword
          }
          closeNewPasswordAndshowLoginModel={CloseNewPasswordAndshowLoginModel}
        />
      )}
      {showsPasswordUpdateModel && (
        <PasswordUpdateModel
          closeUpdatePasswordModel={CloseUpdatePasswordModel}
          closeUpdatePasswordandShowLogin={CloseUpdatePasswordandShowLogin}
        />
      )} */}
    </div>
  );
};

const AuthButton: React.FC<AuthButtonProps> = ({ buttontext, onClick }) => {
  return (
    <div className="ml-2">
      <button
        className={`w-full px-[24px] h-10 border rounded-[8px] ${
          buttontext === "Register"
            ? "bg-white text-[#1c468e] border-[#1c468e]"
            : "bg-[#1c468e] text-white"
        } rounded-lg justify-center items-center inline-flex`}
        onClick={onClick} // Add onClick handler
      >
        <div className="text-center text-base font-normal text-gilroy-medium  leading-normal">
          {buttontext}
        </div>
      </button>
    </div>
  );
};

export default TopDetail;
